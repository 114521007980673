<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="farmerPage" :fields="fields" :search-model="searchModel"
                :show-selection="true" :item-actions="actions" @onActionClick="handleItemActionClick">
        <template slot="search-items">
          <el-form-item label="" prop="type">
            <el-select v-model="searchModel.type" placeholder="用户类型">
              <el-option :label="type.label" :value="type.value" v-for="type in farmerTypeOptions" :key="type.label">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="webSearch">
            <el-input v-model="searchModel.webSearch" placeholder="农户姓名 手机号"></el-input>
          </el-form-item>
          <el-form-item  label="" prop="province" style="width: 90px">
            <el-input v-model="searchModel.province" placeholder="所在省" style="width: 90px;"></el-input>
          </el-form-item>
          <el-form-item label="" prop="city" style="width: 90px">
            <el-input v-model="searchModel.city" placeholder="市区"  style="width: 90px;"></el-input>
          </el-form-item>
          <el-form-item  label="" prop="area" style="width: 90px">
            <el-input v-model="searchModel.area" placeholder="县/区" style="width: 90px"></el-input>
          </el-form-item>
          <el-form-item  label="" prop="address" style="width: 120px">
            <el-input v-model="searchModel.address" clearable placeholder="详细地址" style="width:120px"></el-input>
          </el-form-item>
        </template>
        <template slot="page-actions">
          <el-button @click="handleAdd" size="mini" type="primary">新增用户</el-button>
        </template>
      <template slot="page-actions">
        <el-button @click="handleExport" size="mini" type="primary">批量导出</el-button>
      </template>
    </page-table>

    <el-dialog :title="'编辑'+pageForm.type == 1?'农户': '公共屋顶'" :visible.sync="page_dialog_visible"
        append-to-body
        :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <el-form :model="pageForm" :rules="pageRules" label-width="120px" ref="pageForm"
               v-loading="page_form_loading">

        <el-form-item label="选择类型" prop="type" v-if="!pageForm.hasOwnProperty('id')"
                      :rules="[{required: true, message: '请选择类型', trigger: 'blur'},]">
          <el-select v-model="pageForm.type" placeholder="类型">
            <el-option :label="type.label" :value="type.value" v-for="type in farmerTypeOptions" :key="type.label">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="pageForm.type == 1 ? '业主姓名': '姓名'" prop="name">
          <el-input placeholder="业主姓名" v-model="pageForm.name" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="统一信用代码" prop="unifiedCreditCode" v-if="pageForm.type == 1"
        :rules="[{required: true, message: '请输入统一信用代码', trigger: 'blur'}]">
          <el-input placeholder="业主姓名" v-model="pageForm.unifiedCreditCode" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts" v-if="pageForm.type == 1"
                      :rules="[{required: true, message: '请输入联系人', trigger: 'blur'}]">
          <el-input placeholder="联系人" v-model="pageForm.contacts" maxlength="20"></el-input>
        </el-form-item>

        <el-form-item label="身份证号" prop="idNo" v-if="pageForm.type != 1">
          <el-input placeholder="身份证号" v-model="pageForm.idNo" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input placeholder="电话" v-model="pageForm.phone" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item :label="pageForm.type == 1 ? '屋顶地址': '地址'" prop="areas">
          <el-cascader ref="areaCascaderRef" v-model="pageForm.areas" :options="pca_options"
                       @change="areaChange"
                       :props="{ checkStrictly: false , label: 'name', value: 'name' ,children: 'children' }"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="pageForm.address" placeholder="详细地址"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="pageForm.remarks" placeholder="备注"></el-input>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="page_dialog_visible = false">取 消</el-button>
          <el-button @click="submit('pageForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>

    <!--派单表单-->
    <el-dialog
        title="派单"
        :visible.sync="dispatch_dialog_visible"
        append-to-body
        :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <el-form :model="dispatchForm" :rules="dispatchRules" label-width="120px" ref="dispatchForm"
               v-loading="dispatch_form_loading">
        <el-form-item label="农户">
          {{ dispatchForm.farmerName }}
        </el-form-item>
        <el-form-item label="业务员" prop="userId">
          <el-select
              v-model="dispatchForm.userId"
              filterable
              remote
              clearable
              reserve-keyword
              placeholder="请输入开发商名称"
              :remote-method="userIdRemoteMethod"
              :loading="searech_user_id_loading">
            <el-option
                v-for="item in user_options"
                :key="item.id"
                :label="(item.developers ? item.developers.name : '') + ' ' + item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="dispatch_dialog_visible = false">取 消</el-button>
          <el-button @click="dispatchSubmit('dispatchForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {
  farmerPage,
  farmerCreate,
  farmerUpdate,
  farmerDelete,
  salesmanPage,
  farmerDispatchOrder,
  farmerExport,
  farmerRoofCreate,
  farmerRoofUpdate
} from "@/api/common"
import pcaCode from "@/utils/pca-code"
import {PROVINCE_CODES, FARMER_TYPES} from "@/utils/config"
import downloadBlob from "@/function/downloadBlob";

export default {
  name: "Farmers",
  data() {
    return {
      searchModel: {
        webSearch: '',
        type: '',
        province: '',
        city: '',
        area: '',
        address: '',
      },
      farmerTypeOptions: FARMER_TYPES,
      farmerPage: farmerPage,
      fields: [
        {label: 'USERID', key: 'id',width: 180},
        {label: '用户姓名', key: 'name', width: 110},
        {label: '类型', width: 80,
          render({type}, h) {
            return h('div', type == 1 ? '公共屋顶' : '农户')
          }
        },
        {label: '身份证账号', key: 'idNo',width: 180},
        {label: '手机号', key: 'phone', width: 120},
        {label: '所在省', key: 'province', width:80},
        {label: '市区', key: 'city',width: 80},
        {label: '县/区', key: 'area', width: 80},
        {label: '详细地址', key: 'address',align: 'left',width:150},
        {
          label: "业务人员", width: 110,
          render({user}, h) {
            return h('div', user ? user.name : "")
          }
        },
        {label: "业务人员手机号",width: 120, render({user}, h) {return h('div', user ? user.phone : "")}
        },
        // {label: '信用代码', key: 'unifiedCreditCode',width: 160},
        // {label: '联系人', key: 'contacts',width: 80},

        {label: '创建时间', key: 'time',width: 180},
        {label: "备注", key: 'remarks'},
      ],
      actions: [
        {action: 'dispatch', label: '派单', type: 'primary',},
        {action: 'edit', label: '编辑', type: 'primary',},
        {action: 'delete', label: '删除', type: 'primary',},
        {action: 'edit', label: '查看详情', type: 'primary',},
      ],
      //
      page_form_loading: false,
      pageForm: {
        name: '',
        idNo: '',
        phone: '',
        provinceCode: '',
        province: '',
        city: '',
        area: '',
        areas: [],
        address: '',
        remark: '',
        type: '',
        unifiedCreditCode: '',
        contacts: '',
      },
      pageRules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        idNo: [
          {required: true, message: '请输入身份证', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
        ],
        areas: [
          {required: true, message: '请选择地址', trigger: 'blur'},
        ],
        address: [
          {required: true, message: '请输入详细地址', trigger: 'blur'},
        ],
      },
      page_dialog_visible: false,
      pca_options: pcaCode,
      //
      dispatch_form_loading: false,
      dispatchForm: {
        farmerId: '',
        farmerName: '',
        userId: '',
        type: '',
      },
      dispatchRules: {
        userId: [
          {required: true, message: '请选择业务员', trigger: 'blur'},
        ],
      },
      dispatch_dialog_visible: false,
      searech_user_id_loading: false,
      user_options: [],
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {

  },
  mounted() {

  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    handleAdd() {
      this.pageForm = {
        name: '',
        idNo: '',
        phone: '',
        provinceCode: '',
        province: '',
        city: '',
        area: '',
        areas: [],
        address: '',
        remark: '',
        type: 0,
        unifiedCreditCode: '',
        contacts: '',
      }
      this.page_dialog_visible = true
    },
    editAction(item) {
      this.pageForm = {
        id: item.id,
        name: item.name,
        idNo: item.idNo,
        phone: item.phone,
        provinceCode: item.provinceCode,
        province: item.province,
        city: item.city,
        area: item.area,
        areas: [item.province, item.city, item.area],
        address: item.address,
        remark: item.remark,
        version: item.version,
        type: item.type,
        unifiedCreditCode: item.unifiedCreditCode || '',
        contacts: item.contacts || '',
      }
      this.page_dialog_visible = true
    },
    areaChange(val) {
      this.pageForm.province = val[0]
      this.pageForm.city = val[1]
      this.pageForm.area = val[2]
      let company = PROVINCE_CODES.find(item => {
        return item.province == val[0]
      })
      if (company) {
        this.pageForm.provinceCode = company.id
      }
    },
    submit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.page_form_loading = true
        let func
        // eslint-disable-next-line no-prototype-builtins
        if (that.pageForm.hasOwnProperty('id')) {
          func = that.pageForm.type == 1 ? farmerRoofUpdate : farmerUpdate
        } else {
            func = that.pageForm.type == 1 ? farmerRoofCreate : farmerCreate
        }
        func(that.pageForm).then(() => {
          that.page_form_loading = false
          that.$message.success('提交成功')
          that.page_dialog_visible = false
          that.$refs.table.loadData()
        }).catch(() => {
          that.page_form_loading = false
        })
      })
    },
    dispatchAction(item) {
      let that = this
      that.dispatchForm = {
        farmerId: item.id,
        farmerName: item.name,
        userId: '',
        type: item.type
      }
      that.dispatch_dialog_visible = true
    },
    userIdRemoteMethod(query) {
      if (query == '') return false
      let that = this
      that.searech_user_id_loading = true
      salesmanPage({developerName: query}).then(res => {
        that.user_options = res.records
        that.searech_user_id_loading = false
      }).catch(() => {
        that.user_options = []
        that.searech_user_id_loading = false
      })
    },
    dispatchSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.dispatch_form_loading = true
        farmerDispatchOrder(that.dispatchForm).then(() => {
          that.dispatch_form_loading = false
          that.$message.success('提交成功')
          that.dispatch_dialog_visible = false
          that.$refs.table.loadData()
        }).catch(() => {
          that.dispatch_form_loading = false
        })
      })
    },
    deleteAction(item) {
      let that = this
      that.$confirm('此操作将删除农户信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        farmerDelete(item.id).then(() => {
          that.$message.success('操作成功')
          that.$refs.table.loadData()
        }).catch(() => {
          that.$message.error('操作失败')
        })
      })
    },
    handleExport() {
      let that = this, ids = []
      let selected = that.$refs.table.selection
      for (let item of selected){
        ids.push(item.id)
      }
      let data = Object.assign(that.searchModel, {
        exportIds: ids.length ? ids.join(',') : ''
      })
      farmerExport(data).then(res => {
        downloadBlob(res)
      }).catch(err => {
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
